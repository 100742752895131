import React from "react";
import { Route, Routes } from "react-router-dom";

import './App.css';

// import Navbar from "./components/navbar";

import Home from "./home";
import FetchWeather from "./fetch-weather";
import FetchCarbon from "./fetch-carbon";
// import GoogleMap from "./google-map";
import FetchSunrise from "./fetch-sunrise";
import FetchOffshore from "./fetch-offshore";
// import GoogleMap2 from "./google-map-2";





function App() {
  return (
      <div className="App">
          {/*<Navbar />*/}
              <Routes>
                  <Route path='/' element={<Home/>}/>
                  <Route path='/carbon' element={<FetchCarbon/>}/>
                  <Route path='/weather' element={<FetchWeather/>}/>
                  <Route path='/offshore' element={<FetchOffshore/>}/>
                  <Route path='/sunrise' element={<FetchSunrise/>}/>
                  {/*<Route path='/map2' element={<GoogleMap2/>}/>*/}
                  {/*<Route path='/map' element={<GoogleMap/>}/>*/}
              </Routes>

      </div>
  );
}

export default App;

import React, {useEffect, useState} from 'react';
import {VictoryAxis, VictoryBar, VictoryChart, VictoryLabel, VictoryLegend, VictoryStack, VictoryTheme} from 'victory';



const YorkshireElectricityBarchart = () => {
    const [solarData, setSolarData] = useState([]);
    const [windEnergyData, setWindEnergyData] = useState([]);
    const [hydroData, setHydroData] = useState([]);
    const [otherData, setOtherData] = useState([]);
    const [nuclearData, setNuclearData] = useState([]);
    const [gasData, setGasData] = useState([]);
    const [importsData, setImportsData] = useState([]);
    const [coalData, setCoalData] = useState([]);
    const [biomassData, setBiomassData] = useState([]);

        useEffect(() => {
            fetch('https://api.shortdarkapps.com/v1/sunny/carbon/region/5/daysback/2')
                .then((res) => {
                    return res.json();
                })
                .then(
                    (res) => {
                        const reversed = res.reverse();
                        const solarValues = reversed.filter(({date_from}) => date_from.substring(14,16) === '30')
                            .reduce(
                            (acc, character) => ({
                                data: [...acc.data, { x: character.date_from.substring(11, 13), y: character.solar}],
                            }),
                            { data: [] }
                        );
                        const windValues = reversed.filter(({date_from}) => date_from.substring(14,16) === '30')
                            .reduce(
                                (acc, character) => ({
                                    data: [...acc.data, { x: character.date_from.substring(11, 13), y: character.wind}],
                                }),
                                { data: [] }
                            );

                        const hydroValues = reversed.filter(({date_from}) => date_from.substring(14,16) === '30')
                            .reduce(
                                (acc, character) => ({
                                    data: [...acc.data, { x: character.date_from.substring(11, 13), y: character.hydro}],
                                }),
                                { data: [] }
                            );
                        const otherValues = reversed.filter(({date_from}) => date_from.substring(14,16) === '30')
                            .reduce(
                                (acc, character) => ({
                                    data: [...acc.data, { x: character.date_from.substring(11, 13), y: character.other}],
                                }),
                                { data: [] }
                            );
                        const nuclearValues = reversed.filter(({date_from}) => date_from.substring(14,16) === '30')
                            .reduce(
                                (acc, character) => ({
                                    data: [...acc.data, { x: character.date_from.substring(11, 13), y: character.nuclear}],
                                }),
                                { data: [] }
                            );
                        const gasValues = reversed.filter(({date_from}) => date_from.substring(14,16) === '30')
                            .reduce(
                                (acc, character) => ({
                                    data: [...acc.data, { x: character.date_from.substring(11, 13), y: character.gas}],
                                }),
                                { data: [] }
                            );
                        const importsValues = reversed.filter(({date_from}) => date_from.substring(14,16) === '30')
                            .reduce(
                                (acc, character) => ({
                                    data: [...acc.data, { x: character.date_from.substring(11, 13), y: character.imports}],
                                }),
                                { data: [] }
                            );
                        const coalValues = reversed.filter(({date_from}) => date_from.substring(14,16) === '30')
                            .reduce(
                                (acc, character) => ({
                                    data: [...acc.data, { x: character.date_from.substring(11, 13), y: character.coal}],
                                }),
                                { data: [] }
                            );
                        const biomassValues = reversed.filter(({date_from}) => date_from.substring(14,16) === '30')
                            .reduce(
                                (acc, character) => ({
                                    data: [...acc.data, { x: character.date_from.substring(11, 13), y: character.biomass}],
                                }),
                                { data: [] }
                            );
                        setSolarData({ solarValues });
                        setWindEnergyData({ windValues });

                        setHydroData({ hydroValues });
                        setOtherData({ otherValues });
                        setNuclearData({ nuclearValues });
                        setGasData({ gasValues });
                        setImportsData({ importsValues });
                        setCoalData({ coalValues });
                        setBiomassData({ biomassValues });
                    });
        }, []);


    if (solarData.length===0 || windEnergyData.length===0 || hydroData.length===0 || otherData.length===0) {
        return (<p>No wind data</p>);
    }

    return (
    <VictoryChart
        theme={VictoryTheme.material}
    >
        <VictoryLabel text="Energy Generation Mix for Yorkshire, UK" x={50} y={30} />
        <VictoryStack>
            <VictoryBar
                data={solarData.solarValues.data}
                x="x"
                y="y"
                style={{ data: { fill: "#ff0000" }, }}
            />
            <VictoryBar
                data={windEnergyData.windValues.data}
                x="x"
                y="y"
                style={{ data: { fill: "#00ff00" }, }}
            />
            <VictoryBar
                data={hydroData.hydroValues.data}
                x="x"
                y="y"
                style={{ data: { fill: "#0000ff" }, }}
            />
            <VictoryBar
                data={otherData.otherValues.data}
                x="x"
                y="y"
                style={{ data: { fill: "#cccccc" }, }}
            />
            <VictoryBar
                data={nuclearData.nuclearValues.data}
                x="x"
                y="y"
                style={{ data: { fill: "#ff00ff" }, }}
            />
            <VictoryBar
                data={gasData.gasValues.data}
                x="x"
                y="y"
                style={{ data: { fill: "#ffff00" }, }}
            />
            <VictoryBar
                data={importsData.importsValues.data}
                x="x"
                y="y"
                style={{ data: { fill: "#00ffff" }, }}
            />
            <VictoryBar
                data={coalData.coalValues.data}
                x="x"
                y="y"
                style={{ data: { fill: "#000000" }, }}
            />
            <VictoryBar
                data={biomassData.biomassValues.data}
                x="x"
                y="y"
                style={{ data: { fill: "#996633" }, }}
            />
        </VictoryStack>
        <VictoryAxis dependentAxis
                     style={{
            axis: {stroke: "#756f6a"},
            axisLabel: {fontSize: 8, padding: 30},
            ticks: {stroke: "grey", size: 5},
            tickLabels: {fontSize: 8, padding: 5}
        }} />
        <VictoryAxis
            fixLabelOverlap={true}
            style={{
                axis: {stroke: "#756f6a"},
                axisLabel: {fontSize: 8, padding: 30},
                ticks: {stroke: "grey", size: 5},
                tickLabels: {angle :45, fontSize: 8, padding: 5}
            }}
            label="Hour (on date studied)"
        />
        <VictoryLegend x={60} y={70}
                       itemsPerRow={3}
                       height={100}
                       width={100}
                       centerTitle
                       orientation="horizontal"
                       gutter={10}
                       style={{ labels: { fill: "navy", fontSize: 6  }, border: { fill: "white", stroke: "black", opacity: 0.9 }, title: {fontSize: 6, textDecoration: "underline" }, background: { fill: "#ffffff" }, }}
                       data={[
                           { name: "Solar %", symbol: { fill: "#ff0000" } },
                           { name: "Wind %", symbol: { fill: "#00ff00" } },
                           { name: "Hydro %", symbol: { fill: "#0000ff" } },
                           { name: "Other %", symbol: { fill: "#cccccc" } },
                           { name: "Nuclear %", symbol: { fill: "#ff00ff" } },
                           { name: "Gas %", symbol: { fill: "#ffff00" } },
                           { name: "Imported %", symbol: { fill: "#00ffff" } },
                           { name: "Coal %", symbol: { fill: "#000000" } },
                           { name: "Biomass %", symbol: { fill: "#996633" } },
                       ]}
        />
    </VictoryChart>
    );
};
export default YorkshireElectricityBarchart;
import { useState, useEffect } from 'react';
const FetchCarbon = () => {
    const [carbon, setCarbon] = useState([]);
    useEffect(() => {
        fetch('https://api.shortdarkapps.com/v1/sunny/carbon/region/5/daysback/2')
            .then((res) => {
                console.log(res);
                return res.json();
            })
            .then((data) => {
                console.log(data);
                setCarbon(data);
            });
    }, []);
    return (
        <div className='infoBox'>

            {carbon.map((item) => (
                <ul>
                    <li>Date: {item.date_from.substring(0,10)}</li>
                    <li>From: {item.date_from.substring(11,16)}</li>
                    <li>To: {item.date_to.substring(11,16)}</li>
                    <li>Biomass: {item.biomass}</li>
                    <li>Coal: {item.coal}</li>
                    <li>Imports: {item.imports}</li>
                    <li>Gas: {item.gas}</li>
                    <li>Nuclear: {item.nuclear}</li>
                    <li>Other: {item.other}</li>
                    <li>Hydro: {item.hydro}</li>
                    <li>Solar: {item.solar}</li>
                    <li>Wind: {item.wind}</li>
                </ul>
            ))}
        </div>
    );
};
export default FetchCarbon;
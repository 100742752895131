import { useState, useEffect } from 'react';

const FetchSunrise = () => {
    const [suntimes, setSuntimes] = useState([]);
    useEffect(() => {
        fetch('https://api.shortdarkapps.com/v1/sunny/sunrise/daysback/2')
            .then((res) => {
                console.log(res);
                return res.json();
            })
            .then((data) => {
                console.log('sun rise data: ', data);
                setSuntimes(data);
            });
    }, []);

    if (suntimes.length === 0) {
        return (<div>No data!</div>);
    }

    console.log('sunrise: ', suntimes.results);

    return (
        <div>
            <h3>Sunrise/Sunset: {suntimes.results[0].sunrise} / {suntimes.results[0].sunset}</h3>
        </div>
    );
};

export default FetchSunrise;

import { useState, useEffect } from 'react';
const FetchOffshore = () => {
    const [weather, setWeather] = useState([]);
    useEffect(() => {
        fetch('https://api.shortdarkapps.com/v1/sunny/offshore/daysback/2')
            .then((res) => {
                console.log(res);
                return res.json();
            })
            .then((data) => {
                console.log(data);
                setWeather(data);
            });
    }, []);
    return (
        <div>

            {weather.map((item) => (
                <ul>
                    <li>Date: {item.date}</li>
                    <li>Hour: {item.hour}</li>
                    <li>Temp 2m (celcius): {Math.round(item.temperature)}</li>
                    <li>Wind 10m (kmh): {Math.round(item.wind10m)}</li>
                    <li>Wind 100m (kmh): {Math.round(item.wind100m)}</li>
                </ul>
            ))}
        </div>
    );
};
export default FetchOffshore;
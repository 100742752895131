import React from 'react';

import './App.css';

import SolarLineGraph from "./solar-line-graph";
import WindLineGraph from "./wind-line-graph";
import YorkshireElectricityBarchart from "./yorkshire-electricity-barchart";
import FetchSunrise from "./fetch-sunrise";

import solarFarms from './assets/images/solar-farms-yorkshire.png';
import windFarms from './assets/images/wind-farms-yorkshire.png';
import biomass from './assets/images/biomass-yorkshire.png';

const Home = () => {
    let today = new Date();
    let studyDate = new Date(today);
    studyDate.setDate(today.getDate() - 2);
    let dateWords = studyDate.toString().substring(0,15);
    return (
        <div className="main">
            <h1>Sunny in Sheffield?</h1>
            <h3>Study date: {dateWords}</h3>
            <FetchSunrise/>
            <p>
                (All times are in UTC)
            </p>

            <SolarLineGraph/>

            <img src={solarFarms} alt="Solar farms in Yorkshire" className="mapImg" />

            <h2>
                Solar
            </h2>
            <p>
                <a href="https://www.gem.wiki/Tiln_Farm_solar_farm">Tiln Farm solar farm</a> (Nottinghamshire) (50MW)
                53.3455, -0.9423 <br/>
                <a href="https://www.gem.wiki/Raventhorpe_solar_farm">Raventhorpe solar farm</a> (40MW) 53.5604,
                -0.5813 <br/>
                <a href="https://www.gem.wiki/South_Lowfields_solar_farm">South Lowfields solar farm</a> (50MW) 54.3447,
                -1.5605 <br/>
                <a href="https://www.gem.wiki/Skeeby_solar_farm">Skeeby solar farm</a> (40 MW) 54.4111, -1.6989 <br/>
            </p>
            <WindLineGraph/>

            <img src={windFarms} alt="Wind farms in Yorkshire" className="mapImg" />

            <h2>Offshore Wind</h2>
            <p>
                <a href="https://en.wikipedia.org/wiki/Dogger_Bank_Wind_Farm">Dogger Bank Wind Farm</a> 54.75,
                1.916667<br/>
                <a href="https://en.wikipedia.org/wiki/Hornsea_Wind_Farm">Hornsea Wind Farm</a> 53.885, 1.791<br/>
                <a href="https://en.wikipedia.org/wiki/Westermost_Rough_Wind_Farm">Westermost Rough Offshore Wind
                    Farm</a> (210 MW) 53.81, 0.15<br/>
                <a href="https://en.wikipedia.org/wiki/Westermost_Rough_Wind_Farm">Humber Gateway Offshore
                    Windfarm</a> (219MW) 53.644, 0.293<br/>
            </p>

            <h2>Onshore Wind</h2>
            <p>
                <a href="https://en.wikipedia.org/wiki/Royd_Moor_Wind_Farm">Royd Moor Wind Farm</a> (6MW) 53.5375,
                -1.688333<br/>
                <a href="https://www.onpathenergy.com/marr/">Marr Wind Farm</a> (8MW) 53.53311184995804,
                -1.2400816212293515<br/>
                <a href="https://www.gem.wiki/Hook_Moor_wind_farm">Hook Moor Wind Farm</a> (10.3MW) 53.8119,
                -1.3355<br/>
                <a href="https://www.gem.wiki/Penny_Hill_Lane_wind_farm">Penny Hill Wind Farm</a> (19.8MW) 53.3834,
                -1.2975<br/>
                <a href="https://www.gem.wiki/Knabs_Ridge_wind_farm">Knabs Ridge Wind Farm</a> (16MW) 53.9981,
                -1.6474<br/>
                <a href="https://www.gem.wiki/Tween_Bridge_wind_farm">Tween Bridge Wind Farm</a> (44MW) 53.6223, -0.9356<br/>
                <a href="https://www.gem.wiki/Rusholme_wind_farm">Rusholme Wind Farm</a> (24MW) 53.7303, -0.948<br/>
                <a href="https://www.gem.wiki/Fraisthorpe_wind_farm">Fraisthorpe Wind Farm</a> (29.7MW) 54.0479, -0.2307<br/>
                <a href="https://www.gem.wiki/Sixpenny_Wood_wind_farm">Sixpenny wood Wind Farm</a> (33MW) 53.738,
                -0.8114<br/>
                <a href="https://www.gem.wiki/Lissett_Airfield_wind_farm">Lissett Airfield Wind Farm</a> (30MW) 54.0071,
                -0.2885<br/>
                <a href="https://www.gem.wiki/Hall_Farm_Routh_wind_farm">Hall Farm Routh wind farm</a> (24.6MW) 53.8737,
                -0.3609<br/>
                <a href="https://www.gem.wiki/The_Grange_wind_farm">Grange wind Farm (Lincolnshire)</a> (14MW)
                53.6417790620091, -0.7008355288834218<br/>
                <a href="https://www.thewindpower.net/windfarm_en_19301_tedder-hill.php">Tedder Hill Wind
                    Farm</a> (7.5MW) 53.7506214, -0.0263992<br/>
                <a href="https://www.thewindpower.net/windfarm_en_18855_hazlehead.php">Hazlehead Windfarm</a> (6.15MW)
                53.5343252, -1.7307624<br/>
                <a href="https://www.gem.wiki/Sober_Hill_wind_farm">Sober Hill Wind Farm</a> (15MW) 53.8349,
                -0.5967<br/>
                <a href="https://www.gem.wiki/Spaldington_Airfield_wind_farm">Spaldington Airfield Wind
                    Farm</a> (12.5MW) 53.7952, -0.7998<br/>
                <a href="https://www.gem.wiki/Bishopthorpe_wind_farm">Bishopthorpe wind farm</a> (16.4MW) 53.5099,
                -0.0007<br/>
            </p>

            <YorkshireElectricityBarchart/>
            <p>
                While nuclear electricity is used in Yorkshire, I believe there are no nuclear power plants in Yorkshire
                itself, so the electricity consumed must be from elsewhere, I assume.
            </p>
            <p>
                If this is the case (nuclear imported into Yorkshire from elsewhere), then we could also assume that the
                offshore wind and biomass energy may be exported to elsewhere in the country.
            </p>
            <p>
                Imported energy is imported from another country (France, Belgium, Netherlands, Norway, Denmark, Wales
                also receives imports from Ireland).
            </p>

            <h2>Biomass</h2>

            <img src={biomass} alt="Biomass in Yorkshire" className="mapImg" />

            <h2>Sources</h2>

            <p>Electricity Generation Mix - National Grid ESO's <a href="https://carbonintensity.org.uk/">Carbon
                Intensity</a></p>
            <p>Offshore Weather - <a href="https://open-meteo.com/">Open Meteo</a></p>
            <p>Sunrise/Sunset - <a href="https://sunrisesunset.io/">SunriseSunset.io</a></p>

        </div>
    );
};
export default Home;
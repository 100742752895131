import React, {useEffect, useState} from 'react';
import {VictoryAxis, VictoryChart, VictoryLabel, VictoryLegend, VictoryLine, VictoryTheme} from 'victory';



const VictoryLineGraph = () => {
    const [windEnergyData, setWindEnergyData] = useState([]);
    // const [wind, setWind] = useState([]);
    const [offshore, setOffshore] = useState([]);
    const [hornsea, setHornsea] = useState([]);
    const [humber, setHumber] = useState([]);

        useEffect(() => {
            fetch('https://api.shortdarkapps.com/v1/sunny/carbon/region/5/daysback/2')
                .then((res) => {
                    return res.json();
                })
                .then(
                    (res) => {
                        const reversed = res.reverse();
                        const windValues = reversed.filter(({date_from}) => date_from.substring(14,16) === '30')
                            .reduce(
                                (acc, character) => ({
                                    data: [...acc.data, { x: character.date_from.substring(11, 13), y: character.wind}],
                                }),
                                { data: [] }
                            );
                        setWindEnergyData({ windValues });
                    });

/*            fetch('https://api.shortdarkapps.com/v1/sunny/weather/daysback/2')
                .then((res) => {
                    return res.json();
                })
                .then(
                    (res) => {
                        const reversed = res.reverse();
                        const windSpeedValues = reversed.reduce(
                            (acc, character) => ({
                                data: [...acc.data, { name: 'Wind Speed', x: character.humanhour.toString().length > 1 ? character.humanhour.toString() : "0" + character.humanhour.toString(), y: character.wind_speed}],
                            }),
                            { data: [] }
                        );
                        setWind({ windSpeedValues });
                    });*/

            fetch('https://api.shortdarkapps.com/v1/sunny/offshore/lat/54.75/long/1.916667/daysback/2')
                .then((res) => {
                    return res.json();
                })
                .then(
                    (res) => {
                        const reversed = res.reverse();
                        const offshoreValues = reversed.reduce(
                            (acc, character) => ({
                                data: [...acc.data, { x: character.hour, y: character.wind100m}],
                            }),
                            { data: [] }
                        );
                        setOffshore({ offshoreValues });
                    });
            fetch('https://api.shortdarkapps.com/v1/sunny/offshore/lat/53.885/long/1.791/daysback/2')
                .then((res) => {
                    return res.json();
                })
                .then(
                    (res) => {
                        const reversed = res.reverse();
                        const hornseaValues = reversed.reduce(
                            (acc, character) => ({
                                data: [...acc.data, { x: character.hour, y: character.wind100m}],
                            }),
                            { data: [] }
                        );
                        setHornsea({ hornseaValues });
                    });
            fetch('https://api.shortdarkapps.com/v1/sunny/offshore/lat/53.644/long/0.293/daysback/2')
                .then((res) => {
                    return res.json();
                })
                .then(
                    (res) => {
                        const reversed = res.reverse();
                        const humberValues = reversed.reduce(
                            (acc, character) => ({
                                data: [...acc.data, { x: character.hour, y: character.wind100m}],
                            }),
                            { data: [] }
                        );
                        setHumber({ humberValues });
                    });
        }, []);

    if (/*wind.length===0 ||*/ windEnergyData.length===0 || /*offshore.length === 0 ||*/ hornsea.length === 0 || humber.humberValues.length === 0) {
        return (<p>No wind data</p>);
    }

    // console.log(windEnergyData.windValues.data);
    // console.log(wind.windSpeedValues.data);
    // console.log(offshore.offshoreValues.data);
    // console.log(hornsea.hornseaValues.data);
    console.log(humber.humberValues.data);

    return (
    <VictoryChart
        theme={VictoryTheme.material}
    >
        <VictoryLabel text={() => ["Wind Usage in Yorkshire vs.", "Offshore 100m Wind Speed"]} x={50} y={30} />
        <VictoryLine
            name = "Wind Energy %"
            style={{
                data: { stroke: "#00ff00" },
                parent: { border: "1px solid #ccc"}
            }}
            data={windEnergyData.windValues.data}
        />
{/*        <VictoryLine
            name = "Wind Speed"
            style={{
                data: { stroke: "#000000" },
                parent: { border: "1px solid #ccc"}
            }}
            data={wind.windSpeedValues.data}
        />*/}
        {/*<VictoryLine
            name = "Dogger Bank Wind Speed"
            style={{
                data: { stroke: "#ff0000" },
                parent: { border: "1px solid #ccc"}
            }}
            data={offshore.offshoreValues.data}
        />*/}
        <VictoryLine
            name = "Humber Gateway"
            style={{
                data: { stroke: "#ff0000" },
                parent: { border: "1px solid #ccc"}
            }}
            data={humber.humberValues.data}
        />
        <VictoryLine
            name = "Hornsea Wind Speed"
            style={{
                data: { stroke: "#0000ff" },
                parent: { border: "1px solid #ccc"}
            }}
            data={hornsea.hornseaValues.data}
        />
        <VictoryAxis dependentAxis
                     style={{
            axis: {stroke: "#756f6a"},
            axisLabel: {fontSize: 8, padding: 30},
            ticks: {stroke: "grey", size: 5},
            tickLabels: {fontSize: 8, padding: 5}
        }} />
        <VictoryAxis
            fixLabelOverlap={true}
            style={{
                axis: {stroke: "#756f6a"},
                axisLabel: {fontSize: 8, padding: 30},
                ticks: {stroke: "grey", size: 5},
                tickLabels: {angle :45, fontSize: 8, padding: 5}
            }}
            label="Hour (on date studied)"
        />
        <VictoryLegend x={60} y={50}
                       height={100}
                       width={100}
                       centerTitle
                       orientation="vertical"
                       gutter={10}
                       style={{ labels: { fill: "navy", fontSize: 6  }, border: { fill: "white", stroke: "black", opacity: 0.8 }, title: {fontSize: 6, textDecoration: "underline" } }}
                       data={[
                           { name: "Wind Energy %", symbol: { fill: "#00ff00" } },
                           /*{ name: "Wind Speed (m/s)", symbol: { fill: "#000000" } },*/
                           { name: "Humber Gateway Wind Speed 100m (m/s)", symbol: { fill: "#ff0000" } },
                           { name: "Hornsea Wind Speed 100m (m/s)", symbol: { fill: "#0000ff" } }
                       ]}
        />
    </VictoryChart>
    );
};
export default VictoryLineGraph;
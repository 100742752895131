import React, {useEffect, useState} from 'react';
import {
    VictoryAxis,
    VictoryBrushLine,
    VictoryChart,
    VictoryLabel,
    VictoryLegend,
    VictoryLine,
    VictoryTheme
} from 'victory';



const SolarLineGraph = () => {
    const [solarData, setSolarData] = useState([]);
    // const [weather, setWeather] = useState([]);
    const [suntimes, setSuntimes] = useState([]);

        useEffect(() => {
            fetch('https://api.shortdarkapps.com/v1/sunny/carbon/region/5/daysback/2')
                .then((res) => {
                    return res.json();
                })
                .then(
                    (res) => {
                        const reversed = res.reverse();
                        const values = reversed.filter(({date_from}) => date_from.substring(14,16) === '30')
                            .reduce(
                            (acc, character) => ({
                                data: [...acc.data, { name: 'Solar Energy %', x: character.date_from.substring(11, 13), y: character.solar}],
                            }),
                            { data: [] }
                        );
                        setSolarData({ values });
                    });

/*            fetch('https://api.shortdarkapps.com/v1/sunny/weather/daysback/2')
                .then((res) => {
                    return res.json();
                })
                .then(
                    (res) => {
                        const reversed = res.reverse();
                        const values = reversed.reduce(
                            (acc, character) => ({
                                data: [...acc.data, { name: 'Clear sky %', x: character.humanhour.toString().length > 1 ? character.humanhour.toString() : "0" + character.humanhour.toString(), y: 100 - character.clouds}],
                            }),
                            { data: [] }
                        );
                        setWeather({ values });
                    });*/
            fetch('https://api.shortdarkapps.com/v1/sunny/sunrise/daysback/2')
                .then((res) => {
                    console.log(res);
                    return res.json();
                })
                .then((data) => {
                    console.log('sun rise data: ', data);
                    setSuntimes(data);
                });
        }, []);

    if (/*weather.length===0 ||*/ solarData.length===0 || suntimes.length===0) {
        return (<p>No solar data</p>);
    }

    return (
    <VictoryChart
        theme={VictoryTheme.material}
    >
        <VictoryLabel text={() => ["Solar Usage in Yorkshire"]} x={50} y={30} />
        <VictoryLine
            name = "Solar Energy %"
            style={{
                data: { stroke: "#ff0000" },
                parent: { border: "1px solid #ccc"}
            }}
            data={solarData.values.data}
        />
{/*        <VictoryLine
            name = "Clear Sky %"
            style={{
                data: { stroke: "#0000ff" },
                parent: { border: "1px solid #ccc"}
            }}
            data={weather.values.data}
        />*/}
        <VictoryAxis dependentAxis
                     style={{
            axis: {stroke: "#756f6a"},
            axisLabel: {fontSize: 8, padding: 30},
            ticks: {stroke: "grey", size: 5},
            tickLabels: {fontSize: 8, padding: 5}
        }} />
        <VictoryAxis
            fixLabelOverlap={true}
            style={{
                axis: {stroke: "#756f6a"},
                axisLabel: {fontSize: 8, padding: 30},
                ticks: {stroke: "grey", size: 5},
                tickLabels: {angle :45, fontSize: 8, padding: 5}
            }}
            label="Hour (on date studied)"
        />
        <VictoryAxis
            tickValues={[
                parseInt(suntimes.results[0].sunrise.substring(0,4).replace(':', '.')) > 10 ? suntimes.results[0].sunrise.substring(0,4).replace(':', '.') : "0" + parseInt(suntimes.results[0].sunrise.substring(0,4).replace(':', '.')),
                (parseInt(suntimes.results[0].sunset.substring(0,2).trim(':')) + 12).toString(),
            ]}
            gridComponent={<VictoryBrushLine width={20}/>}
            style={{
                axis: {stroke: "#756f6a"},
                axisLabel: {fontSize: 8, padding: 30},
                ticks: {stroke: "grey", size: 5},
                tickLabels: {angle :45, fontSize: 8, padding: 5}
            }}
        />
        <VictoryLegend x={60} y={50}
                       height={100}
                       width={100}
                       centerTitle
                       orientation="vertical"
                       gutter={10}
                       style={{ labels: { fill: "navy", fontSize: 6  }, border: { fill: "white", stroke: "black", opacity: 0.8 }, title: {fontSize: 6, textDecoration: "underline" } }}
                       data={[
                           { name: "Solar Energy %", symbol: { fill: "#ff0000" } },
                           // { name: "Clear Sky %", symbol: { fill: "#0000ff" } },
                       ]}
        />
    </VictoryChart>
    );
};
export default SolarLineGraph;
import { useState, useEffect } from 'react';
const FetchWeather = () => {
    const [weather, setWeather] = useState([]);
    useEffect(() => {
        fetch('https://api.shortdarkapps.com/v1/sunny/weather/daysback/2')
            .then((res) => {
                console.log(res);
                return res.json();
            })
            .then((data) => {
                console.log(data);
                setWeather(data);
            });
    }, []);
    return (
        <div>

            {weather.map((item) => (
                <ul>
                    <li>Date: {item.humandate}</li>
                    <li>Hour: {item.humanhour}</li>
                    <li>Temp: {item.temp}</li>
                    <li>Feels like: {item.feels_like}</li>
                    <li>Clouds: {item.clouds}</li>
                    <li>Visibility: {item.visibility}</li>
                    <li>Wind speed: {item.wind_speed}</li>
                    <li>Wind gusts: {item.wind_gust}</li>
                </ul>
            ))}
        </div>
    );
};
export default FetchWeather;